.hero-section {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: translateX(-50%);
  text-align: left;
  color: #fff;
}

.title {
  font-size: 2rem;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.tracking-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tracking-button:hover {
  background-color: #e64a19;
}

/* Placeholder styles */
.tidio-placeholder {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #333;
  color: #fff;
  z-index: 9999;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* WhatsApp Button styles */
.whatsapp-button {
  position: fixed;
  z-index: 9999;
  bottom: 130px;
  right: 40px; /* Adjust as needed */
  background-color: #25d366; /* WhatsApp green color */
  color: white;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 50px;
  height: 50px;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker green on hover */
}
