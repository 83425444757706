.slider {
    overflow: hidden;
    width: 100%;
    height: 50vh;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  
  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
  }
  
  .slide img {
    width: 40%;
    display: block;
  }
  