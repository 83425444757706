/* heroAnimate.css */
.container {
  position: relative;
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary {
  border: none;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.btn-primary:active {
  transform: scale(1.05);
}
/* heroAnimate.css */

/* Ensure the card container adapts to screen size */
.row {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

/* Hide navigation buttons on small screens */
@media (max-width: 767.98px) {
  .btn {
    display: none;
  }
}
