/* src/index.css or src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.about-caption{
    background-image: url(./assets/pexels-braeson-holland-3640662-9775922.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 40vh;
}


.scramble-text {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .myClass {
    color: red; /* Example style for the new class */
  }
  
/* FAQ CSS */
  .faq-container {
    max-width: 900px;
    margin: auto;
  }
  
  .faq-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #f8f9fa;
  }
  
  .faq-item h6 {
    font-weight: bold;
  }
  
  img {
    border-radius: 8px;
  }
  /* contact form style */
  .left-container{
    background-image: url(./assets/contact-us.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 80%;
    border-radius: 10px;
  }
  .contact-header{
    background-image: url(./assets/contat-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 60vh;
  }
  /* services banner style */
  .service-header{
    background-image: url(./assets/services-logo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 60vh;

  }
  .Track-Section{
    height: 40vh;
    width: 100%;
  }